.section {
  @apply rounded-lg bg-background-primary shadow-lg p-6 mb-6 transition-all duration-500 border border-divider-main;
}

.section:hover {
  @apply shadow-xl;
}

.card {
  @apply rounded-lg shadow-sm bg-background-primary text-text-secondary overflow-hidden;
}

.moduler {
  flex-basis: calc(50% - 10px);
  margin: 5px;
}

.angled-border-right:after,
.angled-border-left:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  background-color: inherit;
  transform: skewX(-15deg);
  border-radius: 8px;
}

.angled-border-right:after {
  right: -8px;
}

.angled-border-left:before {
  left: -8px;
}

.ant-modal.full-screen-modal {
  padding: 0;

  .ant-modal-content {
    border-radius: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-modal-body {
      flex: 1;
    }
  }

  &.no-padding .ant-modal-body {
    padding: 0;
  }

  &.close-button-left .ant-modal-close {
    left: 0;
    right: unset;
  }
  &.close-button-right .ant-modal-close {
    left: unset;
    right: 0;
  }
}

.custom-tabs {
  .ant-tabs-tab {
    padding: 12px 0;
  }

  .ant-tabs-tab-btn {
    padding: 4px 6px;
    @apply text-text-primary;
  }

  .ant-tabs-tab-btn:hover {
    opacity: 0.85;
  }

  .ant-tabs-tab.ant-tabs-tab-active:hover {
    opacity: 1;
  }

  .ant-tabs-ink-bar {
    visibility: hidden;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    @apply rounded-sm;
    background-color: #{"rgba(var(--palette-primary-main-rgb) / 0.2)"};
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  }
}
