@font-face {
  font-family: "Inter Var";
  src: url("/fonts/Inter.var.woff2");
  font-weight: 1 999;
}

:root {
  --status-bar-height: 0px;

  --brand-background-secondary: rgb(240, 255, 244);
  --brand-background-secondary: rgb(198, 246, 213);
  --brand-primary-light: rgb(154, 230, 180);
  --brand-primary-light: rgb(104, 211, 145);
  --brand-primary-main: rgb(72, 187, 120);
  --brand-primary-main: rgb(56, 161, 105);
  --brand-primary-dark: rgb(47, 133, 90);
  --brand-primary-dark: rgb(39, 103, 73);
  --brand-primary-dark: rgb(34, 84, 61);
}

html {
  font-family: "Inter", sans-serif;
  -webkit-tap-highlight-color: transparent;
  // overflow: hidden;
  // overscroll-behavior-y: none;
  height: 100%;
  scroll-behavior: smooth;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter Var", sans-serif;
    font-variation-settings: normal;
  }
}

body {
  scroll-behavior: smooth;
  // position: fixed;
  // touch-action: manipulation;
  // overscroll-behavior-y: none;
  // overflow: hidden;
  // height: 100%;
  max-width: 100vw;
}

body > div:first-child,
#__next {
  // overflow: hidden;
  height: 100%;
}

.wrapper {
  @apply max-w-7xl px-4 md:px-6 mx-auto;
}

::selection {
  @apply bg-primary-light text-primary-contrast #{!important};
}

.scrollable {
  @apply overflow-y-scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scolling: touch;
  overflow-behaviour: contain;
}

.grey-scale {
  @apply bg-text-disabled;
  transition: all 0.1s ease-in;
}

.grey-scale:hover {
  transform: scale(1.2);
}

// Pop animation
.pop {
  animation: pop 0.3s linear 1;
}

@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

// Push animation
.push {
  animation: pulse 0.3s linear 1;
}

@keyframes push {
  50% {
    transform: scale(0.8);
  }
}

// Gradient animation
.sliding-gradient-animation {
  background-image: linear-gradient(
    to right,
    rgba(var(--palette-primary-contrast-rgb) / 1) 0%,
    rgba(var(--palette-primary-contrast-rgb) / 0.7) 33%,
    rgba(var(--palette-primary-contrast-rgb) / 0.4) 66%,
    rgba(var(--palette-primary-contrast-rgb) / 0.1) 100%
  );
  background-size: 200% 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-move 3s linear infinite;
}

@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Ringing animation
.animate-ring {
  animation: ring 3s 3 ease;
  transform-origin: 50% 0px initial;
}

@keyframes ring {
  0% {
    transform: rotate(-15deg);
  }

  2% {
    transform: rotate(15deg);
  }

  4% {
    transform: rotate(-18deg);
  }

  6% {
    transform: rotate(18deg);
  }

  8% {
    transform: rotate(-22deg);
  }

  10% {
    transform: rotate(22deg);
  }

  12% {
    transform: rotate(-18deg);
  }

  14% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-12deg);
  }

  18% {
    transform: rotate(12deg);
  }

  20% {
    transform: rotate(0deg);
  }
}

.animate-slide-in {
  animation: slide-in 1.5s linear infinite;
}

@keyframes slide-in {
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translate(0px);
  }
}

@import "fontawesome";
