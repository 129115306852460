/* PRINT */
$pageHeight: calc(297mm - 2rem);
$pageWidth: calc(210mm - 2rem);

.print {
  display: none;
}

@page {
  margin: 1rem;
}

.print-page {
  -webkit-page-break-after: always;
  -webkit-page-break-before: avoid;
  page-break-after: always;
  page-break-before: avoid;
  min-height: 100%;
}

.print-single-page {
  height: calc($pageHeight - 1px);
  width: $pageWidth;
}

.chrome-break {
  page-break-inside: avoid;
  page-break-before: auto;
  page-break-after: auto;

  td {
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: avoid;
  }
}

.print {
  &.debug {
    z-index: 99999;
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    // width: 210mm;
    // height: 297mm;
    width: $pageWidth;
    height: $pageHeight;
    background: white;
    min-height: auto;
    overflow: scroll;
    box-shadow: 0px 0px 500px rgba(0, 0, 0, 0.5);
  }
}

@media print {
  html,
  body {
    background-color: white;
    overflow: initial;
    height: initial;
  }

  // Hides every small tag which isn't inside .print i.e. modals, popups etc
  body > :not(.print) {
    display: none;
  }

  body > div:first-child,
  #__next {
    overflow: initial;
    height: initial;
  }

  .print {
    display: block;
  }

  .print > * {
    -webkit-print-color-adjust: exact;
  }

  table {
    @apply w-full bg-background-primary;
    -webkit-print-color-adjust: exact;
  }

  tr {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }

  td {
    -webkit-print-color-adjust: exact;
  }

  /* Hide all scrollbars from print papers */
  * {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}
