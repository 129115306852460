@tailwind base;
@tailwind components;

/* purgecss start ignore */

* {
  border-color: var(--palette-divider-main, #e2e8f0);
  scroll-behavior: smooth;
}

// DESIGN BASE
@import "app";
@import "typography";
@import "buttons";
@import "forms";
@import "tables";
@import "ui";

// SPECIAL FUNCTIONS
@import "loading";
@import "print";

// COMPONENTS
// @import "navbar_wrapper";
// @import "switch";

// FRONTEND (only)
@import "react_dates";
@import "custom";
@import "landing_page";

// Bottom sheet
[data-rsbs-overlay] {
  @apply max-w-md;
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}

[data-rsbs-backdrop] {
  z-index: 998;
}

:root {
  --fa-primary-color: currentColor;
  --fa-secondary-color: currentColor;
}

html {
  @apply overflow-x-hidden;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  @apply bg-background-primary text-text-primary;
}

// Remove carousel blue outline
.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

// Full height carousel
.slide___3-Nqo {
  height: 100% !important;
}

/* purgecss end ignore */

@tailwind utilities;
