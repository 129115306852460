input[type="checkbox"] {
  @apply rounded border-divider-main shadow-sm p-2;
  color: var(--brand-primary-main);
}

input[type="checkbox"]:focus {
  @apply border-indigo-300 ring;
  --tw-ring-color: var(--brand-background-secondary);
}

.form {
  @apply max-w-sm mx-auto;
}

.form-note {
  @apply text-xs text-text-disabled mb-2 text-left;
}
.form-block {
  @apply block my-4;
}

.form-label {
  @apply text-text-secondary my-1 block mr-auto text-left;
}

.form-note {
  @apply text-xs text-text-disabled mt-1 mb-2 text-left;
}

.form-input,
.form-select {
  @apply w-full rounded border-divider-main transition-all;
}

.form-input:focus,
.form-select:focus {
  @apply w-full ring-1 ring-primary-light outline-none border-primary-light;
}

.form-input:disabled,
.form-select:disabled,
.form-textarea:disabled {
  @apply bg-gray-300 text-text-disabled;
}

.form-submit {
  @apply min-w-full my-3 text-background-primary py-3 px-5 rounded-full cursor-pointer;
  background: linear-gradient(
    180deg,
    var(--brand-primary-main) 0%,
    var(--brand-primary-dark) 100%
  );
  background-size: 400% 400%;
  background-position: top;
}

.form-submit:focus {
  @apply outline-none;
}

.label {
  @apply h300 text-left block mb-1;
}

.input {
  @apply block appearance-none w-full bg-background-secondary border border-divider-main text-text-secondary py-3 px-4 rounded leading-tight;
}

.input:focus {
  @apply outline-none bg-background-primary border-divider-main;
}

/* no spinners for number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* custom dropdown */
.dropdown {
  @apply absolute top-full mt-1 p-2 rounded-md shadow-lg bg-background-primary text-text-primary z-20;
}

.dropdown-item:hover {
  @apply rounded-md bg-background-secondary cursor-pointer;
}

/* calendar */
.DayPicker-wrapper,
.DayPicker-NavButton {
  outline: none;
}

.DayPicker-Day {
  border-radius: 0;
  outline: none;
}

.DayPicker-Day:hover {
  @apply bg-background-secondary cursor-pointer;
}

.DayPicker-Day--outside,
.DayPicker-Day--disabled {
  @apply pointer-events-none cursor-auto;
}

.DayPicker-Day--today {
  @apply font-bold text-primary-dark;
}

.no-arrow {
  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

/* Antd forms style rules */

/* Extend tailwind utilities, due to a compile error in the frontend app */
@layer utilities {
  .form-error {
    font-size: 12px;
    text-align: left;
  }

  .position-unset {
    position: unset;
  }
}

.ant-form-item-label > label,
.ant-form p,
.form-label-antd {
  color: theme("colors.text.secondary") !important;
}

.danger {
  .ant-select-selector {
    background-color: theme("colors.danger.light") !important;
    * {
      color: #fff;
    }
  }
  .ant-select-arrow {
    * {
      color: #fff;
    }
  }
}

.secondary {
  .ant-select-selector {
    background-color: theme("colors.background.secondary") !important;
  }
}

/* Add this rule cause the ! operator of tailwind is not working */
.hidden-important {
  display: none !important;
}

.form-label-antd-layout label {
  width: 100%;
}

.form-label-antd-h-unset label {
  height: unset;
}

.ant-form-item-explain-error {
  @apply form-error mt-1;
}

.form-item-flex .ant-form-item-control-input-content {
  @apply flex;
}

/*
 * Need It to remove the position relative from two antd elements,
 * 'cause the EmojiPicker go under the form item (try to remove this rules e see the behavior)
 * COMPONENT: Body.tsx/65 (apps/backend/components/communication/notifications)
 */
.form-item-no-relative .ant-col,
.form-item-no-relative .ant-form-item-control-input {
  @apply position-unset;
}

.ant-col {
  min-height: unset !important;
}

.ant-form-item.min-h-fit .ant-form-item-control-input {
  min-height: fit-content;
}
