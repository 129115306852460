/* TABLES */

.table {
  @apply border border-divider-main bg-background-primary w-full;
  /* border-collapse: collapse; */
}

.table tr {
  @apply border-l border-r border-divider-main;

  &:hover {
    @apply bg-background-secondary;
  }
}

.table th {
  @apply bg-background-secondary border-divider-main border-b px-3 py-2 text-text-secondary font-medium text-sm text-left;
}

.table th.highlighted {
  @apply bg-purple-200 text-purple-700 font-semibold bg-opacity-50 text-sm text-left;
  border-bottom-color: #d6bcfa;

  span {
    font-weight: 500 !important;
  }
}

.ant-table {
  @apply rounded-t-lg;

  thead.ant-table-thead {
    @apply rounded-t-lg;
  }

  th.ant-table-cell {
    text-transform: uppercase;
    @apply text-xs text-gray-700 uppercase px-4 py-3;

    &.highlighted {
      @apply bg-purple-200 text-purple-700 bg-opacity-50 border-purple-200;
      // border-bottom-color: #d6bcfa;
    }
  }

  td.highlighted {
    @apply bg-purple-200 text-purple-700 bg-opacity-10 border-purple-200;
    // border-bottom-color: #d6bcfa;
  }
}

.table td {
  // max-width: 0px;
  border-right-color: #eeeeee;
  border-bottom-color: #f5f5f5;
  @apply border-b px-3 py-2 border-background-secondary text-text-secondary font-normal text-sm text-left;
  width: 1%;
  white-space: nowrap;
}

.table tr.deleted-child td {
  @apply text-danger-main;
}

.table td.highlighted {
  @apply bg-purple-100 border-purple-200 text-purple-800;
}

.table td:first-child,
.table th:first-child {
  @apply border-l;
  border-left-color: #eeeeee;
}

.table td:last-child,
.table th:last-child {
  @apply border-r;
  border-right-color: #eeeeee;
}

.table tr:last-child td,
.table tr:last-child th {
  @apply border-b;
  border-bottom-color: #eeeeee;
}

.table .separator {
  @apply bg-background-secondary border-t border-divider-main h200 text-text-primary;

  &:hover {
    @apply bg-background-secondary;
  }

  td {
    @apply py-1 px-6 capitalize text-left text-text-secondary font-medium text-sm;
  }
}

.table .totals {
  @apply bg-background-secondary border-t border-b py-1;
}

.table .alternate-rows:nth-child(2n) {
  @apply bg-background-primary;
  -webkit-print-color-adjust: exact;
}

.table .alternate-rows:nth-child(2n + 1) {
  @apply bg-background-secondary;
  -webkit-print-color-adjust: exact;
}
